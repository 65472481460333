<template>
  <div class="gmv-process-modal">
    <b-modal
      v-model="openModal"
      @hide="hideModal"
      class="gmv-process-modal-container"
      id="bank-modal"
      scrollable
      title="Procese sus documentos"
    >
      <div id="bancolombia-modal" v-if="currentRoute == 'bancolombia'">
        <b-card no-body>
          <b-tabs card>
            <b-tab active title="Base final">
              <form id="form" name="form" v-on:submit.prevent="">
                <b-card-text
                  v-loading="Loading"
                  element-loading-text="Procesando..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(245 , 229, 229, 0.8)"
                  style="width: 100%"
                >
                  <div class="inputs">
                    <label class="inputs__title">Archivos</label>
                    <div class="inputs__grid">
                      <b-input
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="ruta_base"
                        size="sm"
                        type="text"
                        class="gmv-process-modal-form__inputs"
                        v-model="processFinalBaseBancolombia.ruta_base"
                        placeholder="Ruta base*"
                      ></b-input>
                      <b-form-select
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        :options="filesDesmontes"
                        class="gmv-process-modal-form__inputs"
                        v-model="processFinalBaseBancolombia.ruta_desmontes"
                      >
                        <template #first>
                          <b-form-select-option disabled
                            >--Por favor seleccione una ruta desmontes
                            --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <hr />
                  <div class="inputs">
                    <label class="inputs__title">No interesados</label>
                    <div class="inputs__grid">
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_inicial_no_interesados"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="
                          processFinalBaseBancolombia.fecha_inicial_no_interesados
                        "
                        placeholder="Fecha inicial*"
                      ></b-form-datepicker>
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_final_no_interesados"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="
                          processFinalBaseBancolombia.fecha_final_no_interesados
                        "
                        placeholder="Fecha final*"
                      ></b-form-datepicker>
                    </div>
                  </div>
                  <hr />
                  <div class="inputs">
                    <label class="inputs__title">Referidos</label>
                    <div class="inputs__grid">
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_inicial_no_rediferidos"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="
                          processFinalBaseBancolombia.fecha_inicial_rediferidos
                        "
                        placeholder="Fecha inicial*"
                      ></b-form-datepicker>
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_final_no_rediferidos"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="
                          processFinalBaseBancolombia.fecha_final_rediferidos
                        "
                        placeholder="Fecha final*"
                      ></b-form-datepicker>
                    </div>
                  </div>
                  <hr />
                  <div class="inputs">
                    <label class="inputs__title">Referidos 360</label>
                    <div class="inputs__grid">
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_inicial_rediferidos_360"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="
                          processFinalBaseBancolombia.fecha_inicial_rediferidos_360
                        "
                        placeholder="Fecha inicial*"
                      ></b-form-datepicker>
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_final_rediferidos_360"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="
                          processFinalBaseBancolombia.fecha_final_rediferidos_360
                        "
                        placeholder="Fecha final*"
                      ></b-form-datepicker>
                    </div>
                  </div>
                  <hr />
                  <div class="gmv-process-modal-button">
                    <Button
                      class="upload-button"
                      type="submit"
                      @click="postFinalBase"
                      >Procesar
                    </Button>
                  </div>
                </b-card-text>
              </form>
            </b-tab>
            <template #tabs-end>
              <li role="presentation" style="margin-left: 40rem">
                Tipo de procesos
              </li>
            </template>
          </b-tabs>
        </b-card>
      </div>
      <div id="santander-modal" v-else-if="currentRoute == 'santander'">
        <b-card no-body>
          <b-tabs card>
            <b-tab active title="Base teléfonos">
              <form id="form" name="form" v-on:submit.prevent="">
                <b-card-text
                  v-loading="Loading"
                  element-loading-text="Procesando..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(245 , 229, 229, 0.8)"
                  style="width: 100%"
                >
                  <div class="inputs">
                    <label class="inputs__title">Archivos</label>
                    <div class="inputs__grid">
                      <b-input
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="ruta telefonos moviles"
                        size="sm"
                        type="text"
                        class="gmv-process-modal-form__inputs"
                        v-model="processTelephoneBaseSantander.cellphones_route"
                        placeholder="Ruta teléfonos móviles*"
                      ></b-input>
                    </div>
                  </div>
                  <hr />
                  <div class="gmv-process-modal-button">
                    <Button
                      class="upload-button"
                      type="submit"
                      @click="postTelephoneBaseSantander"
                      >Procesar
                    </Button>
                  </div>
                </b-card-text>
              </form>
            </b-tab>
            <template #tabs-end>
              <li role="presentation" style="margin-left: 40rem">
                Tipo de procesos
              </li>
            </template>
          </b-tabs>
        </b-card>
      </div>
      <div id="bbva-modal" v-else-if="currentRoute == 'bbva'">
        <b-card no-body>
          <b-tabs card>
            <b-tab active title="Base comercial">
              <form id="form" name="form" v-on:submit.prevent="">
                <b-card-text
                  v-loading="Loading"
                  element-loading-text="Procesando..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(245 , 229, 229, 0.8)"
                  style="width: 100%"
                >
                  <hr />
                  <div class="inputs">
                    <label class="inputs__title">Archivos</label>
                    <div class="inputs__grid">
                      <b-input
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="ruta preaprobados"
                        size="sm"
                        type="text"
                        class="gmv-process-modal-form__inputs"
                        v-model="processComercialBase.commercial_route"
                        placeholder="Ruta preaprobados*"
                      ></b-input>
                      <div class="inputs__number">
                        <label class="inputs__title">Numero base bex</label>
                        <el-input-number
                          :min="1"
                          size="mini"
                          v-model="processComercialBase.number_base"
                        ></el-input-number>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="inputs">
                    <label class="inputs__title">Productos</label>
                    <div class="row">
                      <div
                        v-for="(product, index) in productsOptions"
                        :key="index"
                        class="col-xs-12 col-sm-4"
                      >
                        <label class="inputs__title">{{ product.label }}</label>
                        <el-input-number
                          :min="0"
                          :max="13"
                          size="mini"
                          v-model="priority[index]"
                          @change="handleChange(product.value, index)"
                        ></el-input-number>
                        <el-select
                          v-if="
                            product.label == 'Vehiculo' ||
                            product.label == 'Consumo' ||
                            product.label == 'Compra de cartera' ||
                            product.label == 'Libranza' ||
                            product.label == 'Hipotecario'
                          "
                          required
                          title="Campo obligatorio, seleccionar en orden de prioridad"
                          aria-label="required"
                          size="mini"
                          v-model="quotas[`${product.value}`]"
                          multiple
                          placeholder="Plazos"
                          @change="handleBbvaSelect(product.value, index)"
                        >
                          <el-option
                            v-for="(item, index) in options"
                            :key="index"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="gmv-process-modal-button">
                    <Button
                      class="upload-button"
                      type="submit"
                      @click="postComercialBase"
                      >Procesar
                    </Button>
                  </div></b-card-text
                >
              </form>
            </b-tab>
            <template #tabs-end>
              <li role="presentation" style="margin-left: 40rem">
                Tipo de procesos
              </li>
            </template>
          </b-tabs>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>
import router from "@/router";
import MultiSelect from "primevue/multiselect";
import { required } from "vuelidate/lib/validators";
import Button from "primevue/button";
import { Loading } from "element-ui";
import swal from "sweetalert";

export default {
  inject: ["reload"],
  name: "BankProcessModal",
  props: {
    fileInfo: Object,
    showModal: Boolean,
    bankName: Array,
  },
  data() {
    return {
      options: [
        {
          value: "Option1",
          label: "24",
        },
        {
          value: "Option2",
          label: "36",
        },
        {
          value: "Option3",
          label: "48",
        },
        {
          value: "Option4",
          label: "60",
        },
        {
          value: "Option5",
          label: "72",
        },
        {
          value: "Option6",
          label: "84",
        },
        {
          value: "Option7",
          label: "96",
        },
        {
          value: "Option8",
          label: "108",
        },
        {
          value: "Option9",
          label: "120",
        },
        {
          value: "Option10",
          label: "180",
        },
      ],
      productsOptions: [
        { label: "Tarjeta", value: "tarjeta" },
        { label: "Cesion", value: "cesion" },
        { label: "Rotativo", value: "rotativo" },
        { label: "Retanqueo libranza", value: "retanqueo_libranza" },
        { label: "Retanqueo consumo", value: "retanqueo_consumo" },
        { label: "Retanqueo hipotecario", value: "retanqueo_hipotecario" },
        { label: "Retanqueo vehiculo", value: "retanqueo_vehiculo" },
        { label: "Aumento de cupo", value: "aumento_de_cupo" },
        { label: "Vehiculo", value: "vehiculo" },
        { label: "Compra de cartera", value: "compra_de_cartera" },
        { label: "Consumo", value: "consumo" },
        { label: "Libranza", value: "libranza" },
        { label: "Hipotecario", value: "hipotecario" },
      ],
      products: {},
      quotas: [],
      priority: [],
      num4: 1,
      currentRoute: null,
      /*This section is for the definition of the bancolombia bank data*/
      filesDesmontes: [],
      processFinalBaseBancolombia: {
        ruta_base: null,
        ruta_desmontes: null,
        fecha_inicial_no_interesados: null,
        fecha_final_no_interesados: null,
        fecha_inicial_referidos: null,
        fecha_final_referidos: null,
        fecha_inicial_referidos_360: null,
        fecha_final_referidos_360: null,
      },
      /*This section is for the definition of the santander bank data*/
      processTelephoneBaseSantander: {
        cellphones_route: null,
      },
      /*This section is for the definition of the bbva bank data*/
      processComercialBase: {
        commercial_route: null,
        number_base: null,
      },
      countValidation: 0,
      Loading: false,
      openModal: false,
    };
  },
  components: {
    Button,
    Loading,
    MultiSelect,
  },
  created() {
    this.currentRoute = router.currentRoute.params.bankName;
    /*Bancolombia Bank*/
  },
  watch: {
    /*Bancolombia Bank*/
    fileInfo() {
      this.cleanInputsBancolombia();
      if (this.fileInfo.bank == "bancolombia") {
        if (
          this.fileInfo.type == "contener" ||
          this.fileInfo.type == "revolvente"
        ) {
          this.processFinalBaseBancolombia.ruta_base = this.fileInfo.name;
        } else if (this.fileInfo.type == "rechazos_simulacion") {
          this.processBexBase.ruta_rechazos = this.fileInfo.name;
        }
      } else if (this.fileInfo.bank == "santander") {
        /* this.cleanInputsSantander(); */
        if (this.fileInfo.type == "telefonos") {
          this.processTelephoneBaseSantander.cellphones_route =
            this.fileInfo.name;
        }
      } else if (this.fileInfo.bank == "bbva") {
        /* this.cleanInputsBBVA(); */
        if (this.fileInfo.type == "pre_aprobados") {
          this.processComercialBase.commercial_route = this.fileInfo.name;
        }
      }
    },
    showModal(val) {
      if(val){
        this.getFiles();
      }
      this.showModal == true
        ? (this.openModal = true)
        : (this.openModal = false);
    },
  },
  methods: {
    /*Bancolombia Bank Methods*/
    handleChange(product, index) {
      this.products[`${product}`] = { Priority: this.priority[index] };
      this.processComercialBase["products"] = this.products;
    },
    handleBbvaSelect(product, index) {
      let quotas = this.quotas[`${product}`];
      let quotasObject = {};
      for (let i = 0; i < quotas.length; i++) {
        quotasObject[`${quotas[i]}`] = i + 1;
      }
      this.products[`${product}`] = { Priority: this.priority[index] };
      Object.assign(this.products[`${product}`], quotasObject);
      this.processComercialBase["products"] = this.products;
    },
    hideModal() {
      this.cleanInputsBancolombia();
      this.$emit("closeModal");
    },

    async getFiles() {
      let paramsModal = {
        type: 'desmontes',
        bank: this.bankName,
        skip: 0,
        limit: 20,
      };
      let response = await this.$store.dispatch("files/getFiles", paramsModal);
      this.filesDesmontes = response.data.map((el) => el.name);
    },
    async postFinalBase() {
      this.Loading = true;
      let result = await this.$store.dispatch(
        "processBase/postFinalBase",
        this.processFinalBaseBancolombia
      );
      this.Loading = false;
      if (result.status == 200) {
        this.reload({
          name: "Bancolombia",
        });
      }
    },
    validations() {
      this.processFinalBaseBancolombia = {
        ruta_base: {
          required,
        },
        ruta_desmontes: {
          required,
        },
      };
      this.processBexBase = {
        ruta_rechazos: {
          required,
        },
        ruta_base: {
          required,
        },
        ruta_desmontes: {
          required,
        },
        fecha_inicial_no_interesados: {
          required,
        },
        fecha_final_no_interesados: {
          required,
        },
        fecha_inicial_referidos: {
          required,
        },
        fecha_final_referidos: {
          required,
        },
        fecha_inicial_referidos_360: {
          required,
        },
        fecha_final_referidos_360: {
          required,
        },
      };
    },
    cleanInputsBancolombia() {
      this.processFinalBaseBancolombia = {
        ruta_base: null,
        ruta_desmontes: null,
        fecha_inicial_no_interesados: null,
        fecha_final_no_interesados: null,
        fecha_inicial_referidos: null,
        fecha_final_referidos: null,
        fecha_inicial_referidos_360: null,
        fecha_final_referidos_360: null,
      };
    },
    /* Santander Bank Methods*/
    async postTelephoneBaseSantander() {
      this.Loading = true;
      let result = await this.$store.dispatch(
        "processBase/postTelephoneBase",
        this.processTelephoneBaseSantander
      );
      this.Loading = false;
      if (result.status == 200) {
        this.reload({
          name: "Santander",
        });
      }
    },
    validationsSantander() {
      this.processTelephoneBaseSantander = {
        cellphones_route: {
          required,
        },
      };
    },
    cleanInputsSantander() {
      this.processTelephoneBaseSantander = {
        cellphones_route: null,
      };
    },
    /*BBVA Bank Methods*/
    async postComercialBase() {
      let validate = this.validationPriorities();
      if (validate) {
        this.Loading = true;
        let result = await this.$store.dispatch(
          "processBase/postComercialBase",
          this.processComercialBase
        );
        this.Loading = false;
        if (result.status == 200) {
          this.reload({ name: "Bbva" });
        }
      } else {
        this.countValidation = 0;
        swal({
          title: `Prioridades Duplicadas`,
          text: "Por favor, intentar nuevamente.",
          icon: "error",
          dangerMode: true,
        });
      }
    },
    validationPriorities() {
      let priorities = [];
      for (let i in this.processComercialBase.products) {
        priorities.push(this.processComercialBase.products[i].Priority);
      }
      for (let i in priorities) {
        for (let j in priorities) {
          if (priorities[i] == priorities[j] && i != j) {
            this.countValidation += 1;
          }
        }
      }
      if (this.countValidation == 0) {
        return true;
      } else {
        return false;
      }
    },
    validations() {
      this.processComercialBase = {
        commercial_route: { required },
        number_base: { required },
      };
    },
    cleanInputs() {
      this.processComercialBase = {
        commercial_route: null,
        number_base: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@include upload-button;

::v-deep .p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
  font-size: 0.875rem;
}

::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60rem;
  pointer-events: auto;
  background-color: #fff;
  border-radius: 0.3rem;
}

::v-deep .modal.show .modal-dialog {
  transform: none;
  max-width: 60rem;
}

::v-deep button.close {
  border: none;
  background: none;
}

hr {
  margin-bottom: 0rem;
}
.col-sm-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  align-items: center;
}
.el-select {
  display: inline-block;
  position: relative;
  width: 8rem;
}
.inputs {
  display: flex;
  flex-direction: column;
  &__title {
    color: #717274;
    margin-bottom: 0;
  }
  &__number{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.gmv-process-modal-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__inputs {
    color: black;
    background-color: white;
    margin: 5px;
    height: auto;
  }

  &__select {
    background-color: white;
    margin-top: 5px;
    height: 35px;
    border-radius: 5px;
  }

  &__title {
    color: $color-primary-company;
    text-align: center;
    padding-top: 15px;
  }

  &__date {
    text-align: end;
    margin: 5px;
    border: 1px solid #ced4da;
  }
}

.gmv-process-modal-button {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}

::v-deep .modal-footer {
  display: none;
}
</style>